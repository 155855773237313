<template>
  <div>
    <v-container>
      <v-breadcrumbs
        :items="breadcrumbs"
        class="pa-0 pb-2"
        divider=">"
      ></v-breadcrumbs>
      <v-row align="center">
        <v-col cols="auto" class="pr-12">
          <h1>Meta Data</h1>
        </v-col>
        <v-col class="pl-12">
          <v-text-field
            label="Search"
            v-model="searchTerm"
            outlined
            dense
            hide-details
            prepend-inner-icon="mdi-magnify"
          ></v-text-field>
        </v-col>
        <v-col cols="auto">
          <v-row align="center" justify="end" no-gutters>
            <v-tooltip bottom>
              <template v-slot:activator="{ on }">
                <v-btn
                  depressed
                  color="accent"
                  class="mr-2"
                  v-on="on"
                  @click="$refs.metaDataDialog.open()"
                >
                  <v-icon small>mdi-plus</v-icon>
                </v-btn>
              </template>
              <span>Add New Page</span>
            </v-tooltip>

            <v-btn
              depressed
              light
              :to="{ name: 'module-seo-seo-meta-data-archived' }"
            >
              <v-icon left>mdi-archive</v-icon>View Archived
            </v-btn>
          </v-row>
        </v-col>
      </v-row>
      <v-data-table
        :headers="tableHeaders"
        :items="pages"
        no-data-text="There are currently no pages"
      >
        <template v-slot:item.title="{ item }">
          <div v-if="item.title">
            <v-icon color="green">mdi-check</v-icon>
          </div>
          <div v-else>
            <v-icon color="red">mdi-close-box-outline</v-icon>
          </div>
        </template>

        <template v-slot:item.description="{ item }">
          <div v-if="item.description">
            <v-icon color="green">mdi-check</v-icon>
          </div>
          <div v-else>
            <v-icon color="red">mdi-close-box-outline</v-icon>
          </div>
        </template>

        <template v-slot:item.canonical_url="{ item }">
          <div v-if="item.canonical_url">
            <v-icon color="green">mdi-check</v-icon>
          </div>
          <div v-else>
            <v-icon color="red">mdi-close-box-outline</v-icon>
          </div>
        </template>

        <template v-slot:item.actions="{ item }">
          <v-tooltip bottom>
            <template v-slot:activator="{ on }">
              <v-btn
                x-small
                depressed
                class="blue lighten-4 blue--text mr-2"
                v-on="on"
                @click="$refs.metaDataDialog.open(item)"
              >
                <v-icon x-small>mdi-pencil</v-icon>
              </v-btn>
            </template>
            <span>Edit</span>
          </v-tooltip>

          <v-tooltip bottom>
            <template v-slot:activator="{ on }">
              <v-tooltip bottom>
                <template v-slot:activator="{ on }">
                  <v-btn
                    x-small
                    depressed
                    color="red lighten-4 red--text"
                    v-on="on"
                    @click="openDelete(item)"
                  >
                    <v-icon x-small>mdi-archive</v-icon>
                  </v-btn>
                </template>
                <span>Archive</span>
              </v-tooltip>
            </template>
            <span>Archive Meta</span>
          </v-tooltip>
        </template>
      </v-data-table>
    </v-container>
    <v-dialog v-model="deletePage.dialog" persistent max-width="400">
      <v-card>
        <v-card-title class="headline">Archive Page</v-card-title>
        <v-card-text
          >Are you sure you wish to archive
          {{ deletePage.page.url }}?</v-card-text
        >
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="error" depressed @click="resetDelete">No, cancel</v-btn>
          <v-btn
            color="success"
            depressed
            :loading="deletePage.loading"
            @click="saveDelete"
            >Yes</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>
    <meta-data-dialog ref="metaDataDialog" />
  </div>
</template>

<script>
import MetaDataDialog from "./components/MetaDataDialog.vue";

export default {
  components: {
    MetaDataDialog,
  },

  data() {
    return {
      searchTerm: "",
      breadcrumbs: [
        {
          text: "SEO",
          disabled: true,
        },
        {
          text: "Meta Data",
          disabled: true,
        },
      ],
      tableHeaders: [
        { text: "URL", value: "url" },
        { text: "Title", value: "title" },
        { text: "Description", value: "description" },
        { text: "Canonical url", value: "canonical_url" },
        { text: "Actions", value: "actions", sortable: false, align: "right" },
      ],
      deletePage: {
        dialog: false,
        page: {},
        loading: false,
      },
    };
  },

  methods: {
    openDelete(page) {
      this.deletePage.page = page;
      this.deletePage.dialog = true;
    },

    resetDelete() {
      this.deletePage.dialog = false;
      this.deletePage.page = {};
      this.deletePage.loading = false;
    },

    saveDelete() {
      this.deletePage.loading = true;

      this.$store
        .dispatch("seo/pageStore/archivePage", {
          appId: this.$route.params.id,
          pageId: this.deletePage.page.id,
        })
        .then(() => {
          this.resetDelete();
        })
        .catch(() => {
          this.deletePage.loading = false;
        });
    },
  },

  computed: {
    pages() {
      let pages = this.$store.getters["seo/pageStore/allPages"];

      if (this.searchTerm !== "") {
        pages = pages.filter((c) => {
          const title = c.title.toLowerCase();

          const searchTerm = this.searchTerm.toLowerCase();

          return title.includes(searchTerm);
        });
      }

      return pages;
    },
  },
};
</script>
