<template>
  <v-dialog v-model="dialog" scrollable max-width="600" @click:outside="reset">
    <v-card>
      <v-card-title class="headline">
        {{ isEditing ? "Edit" : "Add a new" }} Meta
      </v-card-title>
      <v-divider></v-divider>
      <v-card-text class="pt-5 grey lighten-4" ref="formWrapper">
        <v-form @submit.prevent="save" method="post" id="meta-form">
          <v-text-field
            label="URL *"
            v-model="fields.url"
            outlined
            background-color="white"
            :error="errors.hasOwnProperty('url')"
            :error-messages="errors['url']"
          ></v-text-field>
          <v-text-field
            label="Title *"
            v-model="fields.title"
            outlined
            background-color="white"
            :error="errors.hasOwnProperty('title')"
            :error-messages="errors['title']"
          ></v-text-field>
          <v-textarea
            label="Meta Description"
            v-model="fields.description"
            outlined
            background-color="white"
            :error="errors.hasOwnProperty('description')"
            :error-messages="errors['description']"
          ></v-textarea>
          <v-text-field
            label="Canonical Url"
            v-model="fields.canonical_url"
            outlined
            background-color="white"
            :error="errors.hasOwnProperty('canonical_url')"
            :error-messages="errors['canonical_url']"
          ></v-text-field>
        </v-form>
      </v-card-text>
      <v-divider></v-divider>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="secondary" text @click="reset">Close</v-btn>
        <v-btn
          color="accent"
          text
          type="submit"
          :loading="loading"
          form="meta-form"
          >Save</v-btn
        >
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  data() {
    return {
      dialog: false,
      loading: false,
      isEditing: false,
      page: {},
      fields: {
        url: null,
        title: null,
        description: null,
        canonical_url: null,
      },
      errors: {},
    };
  },

  methods: {
    open(page = null) {
      if (page !== null) {
        this.page = page;
        this.isEditing = true;
        this.fields.url = page.url;
        this.fields.title = page.title;
        this.fields.description = page.description;
        this.fields.canonical_url = page.canonical_url;
      }

      this.dialog = true;
    },

    reset() {
      this.dialog = false;
      this.loading = false;
      this.isEditing = false;
      this.page = {};
      this.fields.url = null;
      this.fields.title = null;
      this.fields.description = null;
      this.fields.canonical_url = null;
      this.errors = {};
      this.$refs.formWrapper.scrollTop = 0;
    },

    save() {
      this.loading = true;
      this.errors = {};

      let payload = {
        appId: this.$route.params.id,
        isEditing: this.isEditing,
        fields: this.fields,
      };

      if (this.isEditing) {
        payload.pageId = this.page.id;
      }

      this.$store
        .dispatch("seo/pageStore/savePage", payload)
        .then(() => this.reset())
        .catch((err) => {
          this.errors = err.response.data.errors;
          this.loading = false;
        });
    },
  },
};
</script>
